
import axios from "axios";
import check from "~/store/elementChecker";
export default {
  
  async asyncData({ req, app, context, route, store, $hyperElement }) {
    let host = "";
    if (process.server) {
      host = req.headers.host + route.fullPath;
    } else {
      host = location.host + route.fullPath;
    }
    let preview = "";
    if (app.$cookies.get("preview")) {
      let pr = (app.$cookies.get("preview") || "").split(":::");
      if (pr[1] == store.getters.store.id) {
        preview = `&preview=${pr[0]}`;
      }
    }
    let elements = [];
    try {
      let res = await axios.get(
        `${process.env.axiosUrl}/stores/${
          store.getters.store.id
        }?elements=${"index"}&build=${
          (store.getters.store.build || {}).build_no
        }&styleless=true&elementsonly=true${preview}`,
        {
          headers: {
            shopster: host,
          },
        }
      );
      if ((res.data || {}).id) {
        let es = JSON.parse(JSON.stringify(store.getters.store));
        // es.loadCss = [...(es.loadCss || []), "index"];
        res.data.eshop.elements = res.data.eshop.elements.map((e) => {
          delete e.data;
          return e;
        });
        console.time('populateData')
        es.eshop.elements =
          (await $hyperElement.populateData({
            elements: res.data.eshop.elements,
            page: "index",app
          })) || [];
          console.timeEnd('populateData')
        // res.data.eshop.elements =
        //   (await $hyperElement.populateData({
        //     elements: res.data.eshop.elements,
        //     page: "index",app
        //   })) || [];
        es.eshop.elements = [
          ...store.getters.store.eshop.elements.filter((e) => e.static || e.page != "index"),
          ...es.eshop.elements,
        ];
        store.commit("store", es);
      }
    } catch (e) {
      console.error(e);
    }
  },
 
};
